import axios from '@/config/httpConfig'

// 获取用户信息
export function findDetail (data) {
  return axios.post('/stock/findDetail', data)
}

// 新增股权关系
export function save (data) {
  return axios.post('/stock/save', data)
}
