<template>
  <div class="penetrate-chart">
    <div v-if="showTitle" class="titleBox">
      <span @click="toDetail">{{ recordName }}</span>
      <!-- 股权结构图 -->
      {{ $t("vue_label_account_equityseructure") }}
    </div>
    <div id="penetrateChart"></div>
  </div>
</template>

<!--关联图谱图-->
<script type="text/ecmascript-6">
import * as StockApi from "./api.js";
import * as d3 from 'd3'
// 过渡时间
const DURATION = 0;
// 加减符号半径
// const SYMBOLA_S_R = 9;
// 公司
const COMPANY = "0";
// 人
const PERSON = "1";
export default {
  data () {
    return {
      layoutTree: "",
      diamonds: "",
      d3: window.d3 || d3,
      i: 0,
      hasChildNodeArr: [],
      originDiamonds: "",
      diagonalUp: "",
      diagonalDown: "",
      tree: {},
      rootUp: "",
      rootDown: "",
      svg: "",
      svgW: document.body.clientWidth,
      svgH: 500,
      showTitle: false,
      recordId: this.$route.params.id,
      recordName: "",
    };
  },
  created () {
    // 判断当前路由
    this.showTitle =
      this.$route.name === "EquityPenetrationRelation" ? true : false;
  },
  mounted () {
    // 获取股权架构图数据
    this.getTreeData();
  },
  methods: {
    // 获取股权架构图数据
    getTreeData () {
      StockApi.findDetail({ id: this.recordId }).then((res) => {
        if (
          JSON.stringify(res.data.tree) != "{}" &&
          (res.data.tree.children  || res.data.tree.parents )
        ) {
          this.tree = res.data.tree;
          this.tree.id = this.recordId;
          this.tree.name = res.data.name || "";
          this.recordName = res.data.name || "";

          // 删除penetrateChart并新建penetrateChart
          var div = document.createElement("div");
          div.id = "penetrateChart";
          div.style.overflow = "auto";
          let parentElement =
            document.getElementsByClassName("penetrate-chart")[0];

          document.getElementById("penetrateChart") &&
            document.getElementById("penetrateChart").remove();
          parentElement.appendChild(div);

          this.init();
          this.renderTree();
          this.$nextTick(() => {
            let divPositionTransform = parseInt(
              parseInt(document.querySelector(".penetrate-chart").style.width) /
              4
            );
            document.querySelector("#penetrateChart").scrollLeft =
              divPositionTransform + "px";
          });
        }
      });
    },
    init () {
      let d3 = this.d3;
      // 强制横屏 所以取反
      let svgW = this.svgW;
      let svgH = this.svgH;
      // 方块形状
      this.diamonds = {
        w: 145,
        h: 54,
        // h: 68,
        intervalW: 200,
        intervalH: 150,
      };
      // 源头对象
      this.originDiamonds = {
        w: 190,
      };
      this.layoutTree = d3
        .tree()
        .nodeSize([this.diamonds.intervalW, this.diamonds.intervalH])
        .separation(() => 1);

      // 主图
      this.svg = d3
        .select("#penetrateChart")
        .append("svg")
        .attr("width", svgW)
        .attr("height", svgH)
        .attr("id", "treesvg")
        .attr("style", "position: relative;z-index: 2;")
        .append("g")
        .attr("id", "g")
        .attr("transform", "translate(" + svgW / 2 + "," + svgH / 2 + ")");
    },
    // 渲染树
    renderTree () {
      let d3 = this.d3;
      let upTree = { children: [] };
      let downTree = { children: [] };
      // 拷贝树的数据
      Object.keys(this.tree).map((item) => {
        if (item === "parents") {
          upTree = JSON.parse(JSON.stringify(this.tree));
          upTree.children = !this.tree[item]  ? [] : this.tree[item];
          upTree.parents = null;
        } else if (item === "children") {
          downTree = JSON.parse(JSON.stringify(this.tree));
          downTree.children = !this.tree[item]  ? [] : this.tree[item];
          downTree.parents = null;
        }
      });
      // hierarchy 返回新的结构 x0,y0初始化起点坐标
      this.rootUp = d3.hierarchy(upTree, (d) => d.children);
      this.rootUp.x0 = 0;
      this.rootUp.y0 = 0;

      this.rootDown = d3.hierarchy(downTree, (d) => d.children);
      this.rootDown.x0 = 0;
      this.rootDown.y0 = 0;
      // 上 和 下 结构
      let treeArr = [
        {
          data: this.rootUp,
          type: "up",
        },
        {
          data: this.rootDown,
          type: "down",
        },
      ];
      treeArr.map((item) => {
        if (item.data.children) {
          item.data.children.forEach(this.collapse);
          this.update(item.data, item.type, item.data);
        }
      });
    },
    /*
     *[update 函数描述], [click 函数描述]
     *  @param  {[Object]} source 第一次是初始源对象，后面是点击的对象
     *  @param  {[String]} showtype up表示向上 down表示向下
     *  @param  {[Object]} sourceTree 初始源对象
     */
    update (source, showtype) {//sourceTree
      let _this = this;
      if (source.parents === null) {
        source.isOpen = !source.isOpen;
      }
      let nodes;
      if (showtype === "up") {
        nodes = this.layoutTree(this.rootUp).descendants();
      } else {
        nodes = this.layoutTree(this.rootDown).descendants();
      }
      let links = nodes.slice(1);
      nodes.forEach((d) => {
        d.y = d.depth * this.diamonds.intervalH;
      });

      let node = this.svg
        .selectAll("g.node" + showtype)
        .data(nodes, (d) => d.id || (d.id = showtype + ++this.i));

      let nodeEnter = node
        .enter()
        .append("g")
        .attr("class", (d) =>
          showtype === "up" && !d.depth ? "hide-node" : "node" + showtype
        )
        // 股东一排数据位置调整
        .attr("transform", (d) =>
          showtype === "up"
            ? // ? 'translate(' + d.x + ',' + -d.y + ')'
            "translate(" + d.x + "," + (-d.y + 7) + ")"
            : "translate(" + d.x + "," + d.y + ")"
        )
        .attr("opacity", (d) =>
          showtype === "up" && !d.depth
            ? this.rootDown.data.children.length
              ? 0
              : 1
            : 1
        ); // 拥有下部分则隐藏初始块
      // 创建矩形
      nodeEnter
        .append("rect")
        .attr("type", (d) => d.id)
        .attr("width", (d) =>
          d.depth ? this.diamonds.w : this.originDiamonds.w
        )
        .attr("height", (d) =>
          d.depth
            ? d.type === COMPANY
              ? this.diamonds.h
              : this.diamonds.h - 10
            : 30
        )
        .attr("x", (d) =>
          d.depth ? -this.diamonds.w / 2 : -this.originDiamonds.w / 2
        )
        .attr("y", (d) =>
          d.depth ? (showtype === "up" ? -this.diamonds.h / 2 : 0) : -15
        )
        .attr("stroke", (d) =>
          d.data.type === COMPANY || !d.depth ? "#7A9EFF" : "#7A9EFF"
        )
        .attr("stroke-width", 1)
        .attr("rx", 5)
        .attr("ry", 5)
        .style("fill", (d) => {
          if (d.data.type === COMPANY || !d.depth) {
            return d._children ? "#fff" : d.depth ? "#fff" : "#7A9EFF";
          } else if (d.data.type === PERSON || !d.depth) {
            return d._children ? "#fff" : d.depth ? "#F3F9FE" : "#7A9EFF";
          }
        });

      // 创建圆 加减
      // nodeEnter
      //   .append('circle')
      //   .attr('type', (d) => d.id || (d.id = showtype + 'text' + ++this.i))
      //   .attr('r', (d) =>
      //     d.depth
      //       ? this.hasChildNodeArr.indexOf(d) === -1
      //         ? 0
      //         : SYMBOLA_S_R
      //       : 0
      //   )
      //   .attr('cy', (d) =>
      //     d.depth
      //       ? showtype === 'up'
      //         ? -(SYMBOLA_S_R + this.diamonds.h / 2)
      //         : this.diamonds.h
      //       : 0
      //   )
      //   .attr('cx', 0)
      //   .attr('fill', (d) => (d.children ? '#fff' : '#7A9EFF'))
      //   .attr('stroke', (d) => (d._children || d.children ? '#7A9EFF' : ''))
      //   .on('click', function(d) {
      //     _this.click(d, showtype, sourceTree)
      //     setTimeout(() => {
      //       if (
      //         document.querySelector(`text[type="${d.id}"]`).innerHTML === '-'
      //       ) {
      //         d.isOpen = false
      //         this.innerHTML = '+'
      //         this.setAttribute('fill', '#7A9EFF')
      //         document
      //           .querySelector(`text[type="${d.id}"]`)
      //           .setAttribute('fill', '#fff')
      //         document
      //           .querySelector(`rect[type="${d.id}"]`)
      //           .setAttribute('style', 'fill:#fff')
      //         document.querySelector(`text[type="${d.id}"]`).innerHTML = '+'
      //       } else {
      //         d.isOpen = true
      //         this.setAttribute('fill', '#fff')
      //         document
      //           .querySelector(`text[type="${d.id}"]`)
      //           .setAttribute('fill', '#7A9EFF')
      //         document
      //           .querySelector(`rect[type="${d.id}"]`)
      //           .setAttribute('style', 'fill:#fff')
      //         document.querySelector(`text[type="${d.id}"]`).innerHTML = '-'
      //       }
      //     }, DURATION)
      //   })

      // 持股比例
      nodeEnter
        .append("g")
        .attr("transform", () => "translate(0,0)")
        .append("text")
        .attr("x", (d) => (d.x > 0 ? (showtype === "up" ? -30 : 30) : 30))
        .attr("y", showtype === "up" ? this.diamonds.h : -20)
        .attr("text-anchor", "middle")
        .attr("fill", (d) => (d.data.type === COMPANY ? "#7A9EFF" : "#7A9EFF"))
        .attr("opacity", (d) => (!d.depth ? 0 : 1))
        .text((d) => (showtype === "up" ? d.data.Holding + "%" : ""))
        .style("font-size", "10px")
        .style("font-family", "Microsoft YaHei")
        .style("font-weight", "400");
      nodeEnter
        .append("g")
        .attr("transform", () => "translate(-27,-75)")
        .append("text")
        .attr("x", (d) => (d.x > 0 ? (showtype === "down" ? 25 : 100) : 30))
        .attr("y", showtype === "down" ? this.diamonds.h : -80)
        .attr("text-anchor", "middle")
        .attr("fill", (d) => (d.data.type === COMPANY ? "#7A9EFF" : "#7A9EFF"))
        .attr("opacity", (d) => (!d.depth ? 0 : 1))
        .text((d) =>
          showtype === "down"
            ? this.$i18n.t('c171') + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" + d.data.Holding + "%"      //控股
            : "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" + d.data.Holding + "%"
        )
        .style("font-size", "10px")
        .style("font-family", "Microsoft YaHei")
        .style("font-weight", "400");

      // 公司名称
      // y轴 否表源头的字体距离
      nodeEnter
        .append("text")
        .attr("x", 0)
        .attr("y", (d) => {
          // 如果是上半部分
          if (showtype === "up") {
            // 如果是1层以上
            if (d.depth) {
              return -this.diamonds.h / 2;
            } else {
              // 如果名字长度大于9个
              if (d.data.name.length > 10) {
                return -5;
              }
              return 0;
            }
          } else {
            if (d.depth) {
              return 0;
            } else {
              if (d.data.name.length > 10) {
                return -5;
              }
              return 0;
            }
          }
        })
        .attr("dy", (d) =>
          d.depth ? (d.data.name.length > 10 ? "1.5em" : "2em") : ".3em"
        )
        .attr("text-anchor", "middle")
        .attr("fill", (d) => (d.depth ? "#465166" : "#fff"))
        .text((d) =>
          d.data.name.length > 10 ? d.data.name.substr(0, 10) : d.data.name
        )
        .style("font-size", "12px")
        .style("cursor", "pointer")
        .style("font-family", "PingFangSC-Medium")
        .style("font-weight", "800")
        .on("click", (d) => {
          this.$router.push({
            path: `/commonObjects/detail/${d.path[0].__data__.data.id}/DETAIL`,
          });
        });

      // 名称过长 第二段
      nodeEnter
        .append("text")
        .attr("x", 0)
        .attr("y", (d) => {
          // ? (d.depth ? -this.diamonds.h / 2 : 0) : 0
          if (showtype === "up") {
            if (d.depth) {
              return -this.diamonds.h / 2;
            }
            return 9;
          } else {
            if (!d.depth) {
              return 8;
            }
            return 0;
          }
        })
        .attr("dy", (d) => (d.depth ? "3em" : ".3em"))
        .attr("text-anchor", "middle")
        .attr("fill", (d) => (d.depth ? "#465166" : "#fff"))
        .text((d) => {
          // 索引从第19个开始截取有表示超出
          if (d.data.name.substr(19, 1)) {
            return d.data.name.substr(10, 11) + "...";
          }
          return d.data.name.substr(10, 11);
        })
        .style("font-size", "12px")
        .style("cursor", "pointer")
        .style("font-family", "PingFangSC-Medium")
        .style("font-weight", "800")
        .on("click", (d) => {
          this.$router.push({
            path: `/commonObjects/detail/${d.path[0].__data__.data.id}/DETAIL`,
          });
        });

      // 认缴金额
      // nodeEnter
      //   .append('text')
      //   .attr('x', 0)
      //   .attr('y', showtype === 'up' ? -this.diamonds.h / 2 : 0)
      //   .attr('dy', (d) =>
      //     d.data.name.substr(9, d.data.name.length).length ? '5em' : '4em'
      //   )
      //   .attr('text-anchor', 'middle')
      //   .attr('fill', (d) => (d.depth ? '#465166' : '#fff'))
      //   .text((d) =>
      //     d.data.money
      //       ? d.data.money === ''
      //         ? '认缴金额：非公示'
      //         : `认缴金额：${d.data.money}万元`
      //       : ''
      //   )
      //   .style('font-size', '10px')
      //   .style('font-family', 'PingFangSC-Regular')
      //   .style('font-weight', '500')
      //   .style('color', 'rgba(70,81,102,1)')

      /*
       * 绘制箭头
       * @param  {string} markerUnits [设置为strokeWidth箭头会随着线的粗细发生变化]
       * @param {string} viewBox 坐标系的区域
       * @param {number} markerWidth,markerHeight 标识的大小
       * @param {string} orient 绘制方向，可设定为：auto（自动确认方向）和 角度值
       * @param {number} stroke-width 箭头宽度
       * @param {string} d 箭头的路径
       * @param {string} fill 箭头颜色
       * @param {string} id resolved0表示公司 resolved1表示个人
       * 直接用一个marker达不到两种颜色都展示的效果
       */
      nodeEnter
        .append("marker")
        .attr("id", showtype + "resolved0")
        .attr("markerUnits", "strokeWidth")
        .attr("markerUnits", "userSpaceOnUse")
        .attr("viewBox", "0 -5 10 10")
        .attr("markerWidth", 12)
        .attr("markerHeight", 12)
        .attr("orient", "90")
        .attr("refX", () => (showtype === "up" ? "-5" : "15"))
        .attr("stroke-width", 2)
        .attr("fill", "#7a9eff")
        .append("path")
        .attr("d", "M0,-5L10,0L0,5")
        .attr("fill", "#7a9eff");

      nodeEnter
        .append("marker")
        .attr("id", showtype + "resolved1")
        .attr("markerUnits", "strokeWidth")
        .attr("markerUnits", "userSpaceOnUse")
        .attr("viewBox", "0 -5 10 10")
        .attr("markerWidth", 12)
        .attr("markerHeight", 12)
        .attr("orient", "90")
        .attr("refX", () => (showtype === "up" ? "-5" : "15"))
        .attr("stroke-width", 2)
        .attr("fill", "#7a9eff")
        .append("path")
        .attr("d", "M0,-5L10,0L0,5")
        .attr("fill", "#7A9EFF");

      // 将节点转换到它们的新位置。
       node.transition()
        .duration(DURATION)
        .attr("transform", (d) =>
          showtype === "up"
            ? "translate(" + d.x + "," + -d.y + ")"
            : "translate(" + d.x + "," + d.y + ")"
        );

      // // 代表是否展开的+-号,function this指向当前dom
      // nodeEnter
      //   .append('svg:text')
      //   .attr('type', (d) => d.id || (d.id = showtype + 'text' + ++this.i))
      //   .on('click', function(d) {
      //     _this.click(d, showtype, sourceTree)
      //     setTimeout(() => {
      //       if (this.innerHTML === '-') {
      //         d.isOpen = false
      //         this.innerHTML = '+'
      //         this.setAttribute('fill', '#fff')
      //         document
      //           .querySelector(`circle[type="${d.id}"]`)
      //           .setAttribute('fill', '#7A9EFF')
      //         document
      //           .querySelector(`rect[type="${d.id}"]`)
      //           .setAttribute('style', 'fill:#fff')
      //       } else {
      //         d.isOpen = true
      //         this.innerHTML = '-'
      //         this.setAttribute('fill', '#7A9EFF')
      //         document
      //           .querySelector(`circle[type="${d.id}"]`)
      //           .setAttribute('fill', '#fff')
      //         document
      //           .querySelector(`rect[type="${d.id}"]`)
      //           .setAttribute('style', 'fill:#fff')
      //       }
      //     }, DURATION)
      //   })
      //   .attr('x', 0)
      //   .attr('dy', (d) =>
      //     d.depth
      //       ? showtype === 'up'
      //         ? -(SYMBOLA_S_R / 2 + this.diamonds.h / 2)
      //         : this.diamonds.h + 4
      //       : 0
      //   )
      //   .attr('text-anchor', 'middle')
      //   .attr('fill', (d) => (d._children ? '#fff' : '#7A9EFF'))
      //   .text((d) =>
      //     this.hasChildNodeArr.indexOf(d) !== -1
      //       ? source.depth && d.isOpen
      //         ? '-'
      //         : '+'
      //       : ''
      //   )

      // 将退出节点转换到父节点的新位置.
      let nodeExit = node
        .exit()
        .transition()
        .duration(DURATION)
        .attr("transform", () =>
          showtype === "up"
            ? "translate(" + source.x + "," + -source.y + ")"
            : "translate(" + source.x + "," + parseInt(source.y) + ")"
        )
        .remove();

      nodeExit
        .select("rect")
        .attr("width", this.diamonds.w)
        .attr("height", this.diamonds.h)
        .attr("stroke", "black")
        .attr("stroke-width", 1);

      // 修改线条
      let link = this.svg
        .selectAll("path.link" + showtype)
        .data(links, (d) => d.id);

      // 在父级前的位置画线。
      let linkEnter = link
        .enter()
        .insert("path", "g")
        .attr("class", "link" + showtype)
        .attr("marker-start", (d) => `url(#${showtype}resolved${d.data.type})`) // 根据箭头标记的id号标记箭头
        .attr("stroke", (d) =>
          d.data.type === COMPANY ? "#7A9EFF" : "#7A9EFF"
        )
        .style("fill-opacity", 1)
        .attr("fill", "none")
        .attr("stroke-width", "1px")
        .attr("d", () => {
          let o = {
            x: source.x0,
            y: source.y0,
          };
          return _this.diagonal(o, o, showtype);
        });

      let linkUpdate = linkEnter.merge(link);
      // 过渡更新位置.
      linkUpdate
        .transition()
        .duration(DURATION)
        .attr("d", (d) => _this.diagonal(d, d.parent, showtype));

      // 将退出节点转换到父节点的新位置
      link
        .exit()
        .transition()
        .duration(DURATION)
        .attr("d", () => {
          let o = {
            x: source.x,
            y: source.y,
          };
          return _this.diagonal(o, o, showtype);
        })
        .remove();

      // 隐藏旧位置方面过渡.
      nodes.forEach((d) => {
        d.x0 = d.x;
        d.y0 = d.y;
      });
    },
    // 拷贝到_children 隐藏1排以后的树
    collapse (source) {
      if (source.children) {
        source._children = source.children;
        source._children.forEach(this.collapse);
        source.children = null;
        this.hasChildNodeArr.push(source);
      }
    },
    click (source, showType, sourceTree) {
      // 不是起点才能点
      if (source.depth) {
        if (source.children) {
          source._children = source.children;
          source.children = null;
        } else {
          source.children = source._children;
          source._children = null;
        }
        this.update(source, showType, sourceTree);
      }
    },
    redraw () {
      //强制横屏后 手机端出现touch事件方向
      //错误问题 以及ios 安卓兼容性问题
      //根据浏览器内核判断手机和pc 重新计算方向 顺时针九十度x,y规则 a[0] = b[1]  a[1] = -b[0]
      var html = document.getElementsByTagName("html")[0];
      var width = html.clientWidth;
      var height = html.clientHeight;
      var a = [];
      var u = navigator.userAgent;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        a = this.d3.event.translate; //是安卓
      } else if (isiOS) {
        if (window.orientation == 90 || window.orientation == -90) {
          //判断屏幕方向
          a = this.d3.event.translate;
        } else {
          var b = this.d3.event.translate;
          a[0] = b[1];
          a[1] = -b[0];
        }
      } else {
        //pc端
        if (width < height) {
          var b1 = this.d3.event.translate;
          a[0] = b1[1];
          a[1] = -b1[0];
        } else {
          a = this.d3.event.translate;
        }
      }

      this.svg.attr(
        "transform",
        "translate(" + a + ")" + " scale(" + this.d3.event.scale + ")"
      );
    },
    diagonal (s, d, showtype) {
      let path;
      if (showtype === "up") {
        path = `M ${s.x} ${-s.y + 24}
        C${s.x} -${(s.y + d.y) * 0.45},
         ${s.x} -${(s.y + d.y) * 0.45},
          ${d.x} -${d.y}`;
      } else {
        path = `M ${s.x} ${s.y}
        C${s.x} ${(s.y + d.y) * 0.45},
         ${s.x} ${(s.y + d.y) * 0.45},
          ${d.x} ${d.y}`;
      }
      return path;
    },
    // saveImg () {
    //   html2canvas(document.getElementById("penetrateChart")).then((canvas) => {
    //     const context = canvas.getContext("2d");
    //     context.mozImageSmoothingEnabled = false;
    //     context.webkitImageSmoothingEnabled = false;
    //     context.msImageSmoothingEnabled = false;
    //     context.imageSmoothingEnabled = false;
    //     let base64File = canvas.toDataURL("image/jpg", 1.0);
    //     base64File = base64File.replace(/^.*?,/, "");
    //   });
    // },
    resetSvg () {
      this.d3.select("#treesvg").remove();
      this.init();
    },
    // 跳转到记录详情
    toDetail () {
      this.$router.push({
        path: `/commonObjects/detail/${this.recordId}/DETAIL`,
      });
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.penetrate-chart {
  height: 100%;
  background: #fff;

  overflow: auto;

  .titleBox {
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #080707;
    background: #f6f6f6;

    span {
      float: left;
      color: #006dcc;
      cursor: pointer;
    }
  }
}

#treesvg {
  display: block;
  margin: auto;
  width: 100%;
  height: 100%;
}

#penetrateChart {
  overflow: auto;
}
</style>
